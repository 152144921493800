@media print {
    .no-print {
        display: none;
    }
    #invoice {
        display: block;
    }
        
}
.table th{
    padding: 0 !important;
}